import React from "react";
import "./LoaderPart2.css";
const LoaderPart2 = () => {
  return (
    <div className="loader2_main">
      <div className="ball"></div>
      <div className="shape"></div>
    </div>
  );
};

export default LoaderPart2;
